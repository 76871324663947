<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.96655 13.2802L10.3132 8.93355C10.8266 8.42022 10.8266 7.58022 10.3132 7.06688L5.96655 2.72021"
      stroke="#8F95B2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
