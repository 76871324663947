<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0333 2.72027L5.68666 7.06694C5.17333 7.58027 5.17332 8.42027 5.68666 8.93361L10.0333 13.2803"
      stroke="#8F95B2"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>