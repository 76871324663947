import { Decrypt, Encrypt } from "@/services/crypto";
import { defineStore } from "pinia";

export const clockItUserPermissions = defineStore({
    id: "clock_it_accesss_level",
    state: () => ({
    accessSet: false,
    permissions: {
        time_tracker: false,
        plan_it: false,
        preference: false,
        view_people: false,
        manage_people: false,
        view_projects: false,
        manage_projects: false,
        view_teamsheet: false,
        manage_teamsheet: false,
        basic_reports: false,
        configurations: false,
        categories: false,
        access_level: false,
        view_all_project_teamsheet: false,
        manage_all_project_teamsheet: false,
      }, 
      allPermissions: [],
      superAccess:false,
      can_clock_all_projects:false,
    }),
    persist:{
        serializer: {
            deserialize: (value) => JSON.parse(Decrypt(JSON.parse(value))),
            serialize: (value) => JSON.stringify(Encrypt(JSON.stringify(value)))
          }}
});