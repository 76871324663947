<template>
  <div>
    <div class="pl-10 pr-10">
      <div class="gif-img"></div>
      <h1 class="N800">Oops! Sorry,</h1>
      <h3 v-if="props.maintext" class="N800 textControl">{{ props.maintext }} <br /></h3>
       <h3 v-else class="N800 ">This page is unavailable on Mobile</h3>
       <h3 class="N800 showAlt">This page is unavailable on Mobile</h3>
     
      <p v-if="props.subText" class="N600">{{ props.subText }} <br /></p>
      <p class="N600" v-else>
        For optimum result please continue on PC. <br />
        <!-- <a class="P200 R400 text-bold" href="/contract-manager">Back top page.</a> -->
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  gif?: string;
  maintext?: string;
  subText?: string;
}>();
</script>

<style scoped>
@media screen and (max-width: 745px) {
  .mobile-display-hidden {
    display: none;
  }

  .gif-img {
    width: 300px;
    height: 300px;
    background-image: url("../../assets/search-not-found.gif");
    background-size: cover;
    border-radius: 50%;
    overflow: hidden;
    margin: auto;
  }

  .mobile-messege-notice {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }

  
  .showAlt{
    display: none;
  }

  
  .textControl{
    display: block;
  }
}


@media screen and (max-width: 480px) {
  .textControl{
    display: none;
  }
  

  .showAlt{
    display: block;
  }
}


</style>
