<template>
  <div
    class="
      N700
      flex
      justify-end
      content-center
      bg-[#EDEFF5]
      fixed
      top-0
      pt-8
      pb-4
      left-0
      w-full
    "
    @mouseleave="removeDropdowns"
  >
    <div class="flex justify-between w-full">
      <div class="flex items-center cursor-pointer z-[500]">
        <img
          class="mr-[19px] ml-6 min-h-[5.7px] w-[40px] mt-0.5"
          src="../assets/amalitech-logo3.svg"
          alt="amalitech-logo"
        />
      </div>
      <div class="flex gap-4">
        <div
          class="
            self-center
            cursor-pointer
            flex
            items-center
            gap-2
            bg-[#C1C4D6]
            p-2
            rounded-lg
            responsiveDisplay
          "
          @click="
            [
              (showAppsDropdown = !showAppsDropdown),
              $emit('show-dropdown', showAppsDropdown)
            ]
          "
        >
          <img
            src="../assets/menu-icons/Apps.svg"
            class="appsIcon"
            alt="apps-icon"
          />
          <p class="N900 text-base">Apps</p>
        </div>
        <Popper
          class="tooltip"
          offsetDistance="5"
          offsetSkid="20"
          hover
          placement="right"
          :content="'Help'"
        >
          <div
            @click="redirectToHelp"
            class="
              mt-1
              cursor-pointer
              bg-[#C1C4D6]
              rounded-lg
              flex
              self-center
              gap-2
              p-2
            "
          >
            <img src="../assets/Help.svg" alt="help-icon" />
          </div>
        </Popper>
        <div
          class="flex gap-4 cursor-pointer"
          @click="
            [
              (showProfiledropdown = !showProfiledropdown),
              $emit('show-profileDropdown', showProfiledropdown)
            ]
          "
        >
          <ProfileImageComponent
            :imgSrc="profilePicture"
            :initials="getUserNameInitials(full_name)"
          />
          <p class="self-center N800 username">{{ userName }}</p>
          <img
            src="@/assets/arrow-down.svg"
            class="cursor-pointer h-fit self-center"
            alt="arrow-icon"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useStore } from "@/store/index";
import { getUserNameInitials } from "@/helpers/functions";
import ProfileImageComponent from "@/ui-kit/components/Avatar/ProfileImageComponent.vue";
import { useRouter } from "vue-router";
import Popper from "vue3-popper";

const store = useStore();
const userName = ref(store.user.first_name);

const full_name = computed(
  () => store?.user?.first_name + " " + store?.user.last_name
);
const profilePicture = ref(store.user?.profile_image);

store.$subscribe((_, state) => {
  profilePicture.value = state.user.profile_image;
  userName.value = state.user.first_name;
});

const showProfiledropdown = ref<boolean>(false);
const showAppsDropdown = ref<boolean>(false);
const removeDropdowns = () => {
  showAppsDropdown.value = false;
  showProfiledropdown.value = false;
};

const router = useRouter();

const redirectToHelp = () => {
  router.push("/clock-it-help");
};
</script>

<style>
@media (max-width: 420px) {
  .profile-img {
    border-radius: 50%;
  }
  .username {
    display: none;
  }
}

.tooltip {
  --popper-theme-background-color: #101840;
  --popper-theme-background-color-hover: #000;
  --popper-theme-text-color: #fff;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 0.3em;
  --popper-theme-box-shadow: 0 6px 10px -6px gray;
}
</style>
