<template>
  <RouterLink :to="`${item?.route}`" class="">
    <div
      class="
        flex
        items-center
        gap-4
        rounded-lg
        mr-4
        cursor-pointer
        my-1
        py-3
        pl-4
        mb-4
        text-[#696F8C]
        w-full
      "
      :class="[
        $route.path === item?.route  ? 'bg-[#fff0ea] hover:bg-[#fff0ea]' : ' hover:bg-[#F4F6FA]',
        $route.path.includes(item?.keyWord !)? 'bg-[#fff0ea] text-[#DD5928] hover:bg-[#fff0ea]' : '',
  ]"
      @mouseenter="showToolTip = true"
      @mouseleave="showToolTip = false"
    >
  
      <div v-if="!store.showAll">
        <span
          v-if="showToolTip"
          class="absolute bg-[#101840] px-2 mt-8 N0 rounded-lg text-white"
          >{{ item?.label }}</span
        >
        <slot></slot>
      </div>

      <div v-else>
        <slot></slot>
      </div>
      <span
        class="
          text-base
          sm:hidden
          md:hidden
          lg:block
          xl:block
          whitespace-nowrap
        "
        :class="$route.path === item?.route ? 'O400' : ''"
        v-if="store.showAll"
        :onmouseenter="(showToolTip = true)"
        :onmouseleave="(showToolTip = false)"
        >{{ item?.label }}</span
      >
    </div>
  </RouterLink>
</template>

<script setup lang="ts">
import { PropType, ref } from "vue";
import { useStore } from "@/store/index";

const store = useStore();
defineProps({
  item: {
    type: Object as PropType<{ label: string; route: string; keyWord?: string }>
  }
});

const showToolTip = ref(false);
</script>

