import { AES, enc } from "crypto-ts";

// const key = "snipersnevermisstheirtarget";
const key = "snipersnevermisstheirtarget";

// encryption
export function Encrypt(text: string | string[]) {
  return AES.encrypt(text as string, key).toString();
}

// decryption
export function Decrypt(text: string | string[]) {
  return AES.decrypt(text as string, key).toString(enc.Utf8);
}
