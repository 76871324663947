import PageNotFound from "../../PageNotFound.vue";

export default [
  {
    path: "/time-tracking/:id/time-tracker/day-planner",
    name: "day-planner",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "day-planner" */ "../components/task-planner-components/TaskPlanner.vue"
      )
  },
  {
    path: "/time-tracking/:id/time-tracker/preference",
    name: "preference",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "preference" */ "../components/preference-components/Preference.vue"
      )
  },
  {
    path: "/basic-report",
    name: "basic-report",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "basic-report" */ "../basic-report/BasicReport.vue"
      )
  },
  {
    path: "/team-sheet/:id",
    name: "team-sheet",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "team-sheet" */ "../components/TeamSheet.vue"
      ),
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        meta: { auth: true },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "../components/Dashboard.vue"
          )
      },
      {
        path: "my-team",
        name: "my-team",
        meta: { auth: true },
        component: () =>
          import(/* webpackChunkName: "my-team" */ "../components/MyTeam.vue"),
        children: [
          {
            path: "team-member/:memberId/:projectId/:range?", // This path is relative to the parent route ("my-team")
            name: "team-member",
            meta: { auth: true },
            component: () =>
              import(
                /* webpackChunkName: "team-member" */ "../components/team-sheet-components/TeamMember.vue"
              )
          },
          {
            path: "team-summary", // This path is relative to the parent route ("my-team")
            name: "team-summary",
            meta: { auth: true },
            component: () =>
              import(
                /* webpackChunkName: "team-summary" */ "../components/team-sheet-components/TeamSummaryTable.vue"
              )
          }
        ]
      }
    ]
  },
  {
    path: "/time-tracking/settings",
    name: "settings",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "../components/SettingsComponent.vue"
      ),
    children: [
      {
        path: "configurations",
        name: "configurations",
        meta: { auth: true },
        component: () =>
          import(
            /* webpackChunkName: "configurations" */ "../../settings-config/components/Configurations.vue"
          )
      },
      {
        path: "access-level",
        name: "access-level",
        meta: { auth: true },
        component: () =>
          import(
            /* webpackChunkName: "access-level" */ "../components/TimeTrackerAccessLevel.vue"
          )
      },
      {
        path: "/time-tracking/settings/access-level/create-role",
        name: "create-access-level",
        meta: { auth: true },
        component: () =>
          import(
            /* webpackChunkName: "create-access-level" */ "../components/CreateUpdateTimeTrackerAccessLevel.vue"
          )
      },
      {
        path: "/time-tracking/settings/access-level/edit-role/:id/:defaultAccess",
        name: "edit-access-level",
        meta: { auth: true },
        component: () =>
          import(
                        /* webpackChunkName: "create-access-level" */ "../components/CreateUpdateTimeTrackerAccessLevel.vue"
          )
      },
      {
        path: "/time-tracking/settings/access-level/view/:role",
        name: "view-access-level",
        meta: { auth: true },
        component: () =>
          import(
                        /* webpackChunkName: "view-access-level" */ "../components/ViewPeopleAccessLevel.vue"
          )
      }
    ]
  },
  {
    path: "/time-sheet-details/:id",
    name: "timesheet-details",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "timesheet-details" */ "../components/timeSheetDetailsComponent/TimeSheetDetails.vue"
      )
  },

  {
    path: "/time-tracking/:id/timetracker/people",
    name: "peopele-sheet",
    meta: { auth: true },
    component: () =>
      import(/* webpackChunkName: "people" */ "../views/PeopleSheet.vue"),
      
  
  },
  {
    path: "/time-tracking/:id/timetracker/people/:details",
    name: "peopele-details",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "people" */ "../components/peoples-card/PeopleDetails.vue"
      )
  }
  ,{
    path: "/time-tracking/:id/timetracker/projects",
    name: "projects-hub",
    meta: { auth: true },
    component: () => import("../views/ProjectsHub.vue"),
    children: [
      {
        path: "/time-tracking/:id/timetracker/projects/project-info",
        name: "project-info",
        meta: { auth: true },
        component: () =>
          import(
            /* webpackChunkName: "project-info" */ "../components/project-components/ProjectInfo.vue"
          )
      }
    ]
  },
  {
    path: "/time-tracking/:id?/timetracker/projects/:details?",
    name: "projects-details",
    meta: { auth: true },
    component: () =>
      import("../components/project-components/ProjectDetails.vue")
  },
  {
    path: "/clock-it-help",
    name: "clock-it-help",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "clock-it-help" */ "../help-page/HelpPage.vue"
      )
  },
  {
    path: "/:catchAll(.*)*",
    name: "PageNotFound",
    meta: { auth: true },
    component: PageNotFound
  }
];